import React from 'react';
import classnames from 'classnames';
import {
  FontAwesomeIcon as FortAwesomeFontAwesomeIcon,
} from '@fortawesome/react-fontawesome';
import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

type Props = {
  className?: string;
} & FontAwesomeIconProps;

export const FontAwesomeIcon: React.FC<Props> = ({
  className,
  ...otherProps
}) => {
  const props: FontAwesomeIconProps = {
    className: classnames([className, 'fa']),
    ...otherProps,
  };
  return <FortAwesomeFontAwesomeIcon {...props} />;
};
