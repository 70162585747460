import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { createUseStyles } from 'react-jss';
import {
  faAsterisk,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from './FontAwesomeIcon';

const typeMap = {
  alert: faAsterisk,
  help: faQuestionCircle,
};
const useStyles = createUseStyles({
  toolTip: {
    color: 'white',
    'background-color': 'black',
  },
});

type Props = {
  text: React.ReactNode;
  type: 'alert' | 'help';
  id: string;
};

export const Tooltip: React.FC<Props> = ({
  text, type, id,
}) => {
  const classes = useStyles();

  return (
    <>
      <span
        style={{
          textDecoration: 'underline',
          color: 'blue',
        }}
        id={id}
      >
        <FontAwesomeIcon icon={typeMap[type]} />
      </span>
      <UncontrolledTooltip
        autohide={false}
        className={classes.toolTip}
        placement="top"
        target={id}
      >
        {text}
      </UncontrolledTooltip>
    </>
  );
};
