import React, { useState } from 'react';
import Select from 'react-select';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '../FontAwesomeIcon';
import { getRandomId } from '../utils';

type Props = {
  possibleChoices: Array<string>;
  chosenChoices: Array<string>;
  onChange: (chosenChoices: Array<string>) => void;
  placeholder: string;
  // eslint-disable-next-line react/no-unused-prop-types
  path: string;
};

/* eslint-disable react/no-array-index-key */
export const CardSelector: React.FC<Props> = ({
  possibleChoices,
  chosenChoices,
  onChange,
  placeholder,
}) => {
  const [elementId] = useState(getRandomId());

  return (
    <>
      <ul>
        {chosenChoices.map((chosenChoice, index) => (
          <li key={`${elementId}_neighbor_${index}`}>
            {chosenChoice}{' '}
            <FontAwesomeIcon
              icon={faTimes}
              onClick={() =>
                onChange([
                  ...chosenChoices.slice(0, index),
                  ...chosenChoices.slice(index + 1),
                ])}
            />
          </li>
        ))}
      </ul>
      <Select
        value={{
          value: '',
          label: '',
        }}
        placeholder={placeholder}
        options={possibleChoices
          .filter((choice) => !chosenChoices.includes(choice))
          .map((choice) => ({
            value: choice,
            label: choice,
          }))}
        onChange={(val: any) => {
          onChange([...chosenChoices, val.value]);
        }}
        className="card-modal-select"
      />
    </>
  );
};
