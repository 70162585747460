import React, { useState } from 'react';
import { Input } from 'reactstrap';
import type { Parameters } from '../types';
import { getRandomId } from '../utils';
import { Tooltip } from '../Tooltip';

type Props = {
  parameters: Parameters;
  onChange: (arg0: Parameters) => void;
};

export const PlaceholderInput: React.FC<Props> = ({
  parameters,
  onChange,
}) => {
  const [elementId] = useState(getRandomId());

  return (
    <>
      <h4>
        Placeholder{' '}
        <a
          href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attr-placeholder"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Tooltip
            id={`${elementId}_placeholder`}
            type="help"
            text="Hint to the user as to what kind of information is expected in the field"
          />
        </a>
      </h4>
      <Input
        value={parameters['ui:placeholder']}
        placeholder="Placeholder"
        key="placeholder"
        type="text"
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
          onChange({
            ...parameters,
            'ui:placeholder': ev.target.value,
          });
        }}
        className="card-modal-text"
      />
    </>
  );
};
