import React, { useState } from 'react';
import { getRandomId } from '../utils';
import { Tooltip } from '../Tooltip';

type Props = {
  parameters: {
    name?: string;
    dependents?: Array<{
      children: Array<string>;
      value?: any;
    }>;
    type?: string;
    enum?: Array<string | number>;
    neighborNames?: Array<string>;
    schema?: string;
    [key: string]: any;
  };
};

/* eslint-disable react/no-array-index-key */
export const DependencyWarning: React.FC<Props> = ({
  parameters,
}) => {
  const [elementId] = useState(getRandomId());

  if (
    parameters.enum &&
    parameters.dependents &&
    parameters.dependents.length &&
    parameters.dependents[0].value
  ) {
    // get the set of defined enum values
    const definedVals = new Set<any>([]);
    (parameters.dependents || []).forEach((possibility) => {
      if (possibility.value && possibility.value.enum) {
        possibility.value.enum.forEach((val: any) => definedVals.add(val));
      }
    });
    const undefinedVals: Array<string | number> = [];
    if (Array.isArray(parameters.enum)) {
      parameters.enum.forEach((val) => {
        if (!definedVals.has(val)) undefinedVals.push(val);
      });
    }
    if (undefinedVals.length === 0) return null;
    return (
      <>
        <p>
          Warning! The following values do not have associated dependency
          values:{' '}
          <Tooltip
            id={`${elementId}_valuewarning`}
            type="help"
            text="Each possible value for a value-based dependency must be defined to work properly"
          />
        </p>

        <ul>
          {undefinedVals.map((val, index) => (
            <li key={index}>{val}</li>
          ))}
        </ul>
      </>
    );
  }

  return null;
};
