import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  Input,
  FormGroup,
  FormFeedback,
} from 'reactstrap';
import { createUseStyles } from 'react-jss';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import * as yup from 'yup';
import { FontAwesomeIcon } from './FontAwesomeIcon';
import { fetchLabel } from './utils';
import type {
  Mods,
} from './types';

const useStyles = createUseStyles({
  cardEnumOption: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '.5em',
    '& input': {
      width: '80%',
      marginRight: '1em',
      marginBottom: 0,
    },
    '& .delete-button': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
}); // Input field corresponding to an array of values, add and remove

const validate = async ({
  values,
  mods,
}: {
  values: Array<string>,
  mods?: Mods
}) => {
  yup.setLocale({
    mixed: {
      required: fetchLabel('validations.required', 'This field is required', mods),
    },
  });

  const multipleSchema = yup.array().of(
    yup.string()
      .test('unique', fetchLabel('validations.taken', 'Taken', mods), (value, options) => !(value &&
          ((values.indexOf(value) !== values.lastIndexOf(value)
            && (options.path !== `[${values.indexOf(value)}]`))))),
  );

  return multipleSchema.validate(values);
};

type Props = {
  initialValues: Array<any>;
  names?: Array<string>;
  onChange: (newEnums: Array<any>, newEnumNames?: Array<string>) => void;
  type: string;
  mods?: Mods;
};

export const CardEnumOptions = ({
  initialValues,
  names,
  onChange,
  type,
  mods,
}: Props) => {
  const [errors, setErrors] = useState<Record<string, any>>({});
  const classes = useStyles();
  const possibleValues = [];

  useEffect(() => {
    validate({ values: initialValues, mods })
      .then(() => setErrors({}))
      .catch((err) => {
        setErrors({ [err.path]: err.errors });
      });
  }, [initialValues, mods]);

  for (let index = 0; index < initialValues.length; index += 1) {
    const value = initialValues[index];
    const path = `[${index}]`;

    possibleValues.push(
      <div key={index} className={classes.cardEnumOption}>
        <FormGroup className="col-12">
          <Input
            value={value === undefined || value === null ? '' : value}
            placeholder="Possible Value"
            key={`val-${index}`}
            type={type === 'string' ? 'text' : 'number'}
            onChange={(ev: any) => {
              let newVal;

              switch (type) {
                case 'string':
                  newVal = ev.target.value;
                  break;

                case 'number':
                case 'integer':
                  newVal = parseFloat(ev.target.value);
                  if (Number.isInteger(newVal)) {
                    newVal = parseInt(ev.target.value, 10);
                  }
                  if (Number.isNaN(newVal)) {
                    newVal = 0;
                  }
                  break;

                default:
                  throw new Error(`Enum called with unknown type ${type}`);
              }

              onChange(
                [
                  ...initialValues.slice(0, index),
                  newVal,
                  ...initialValues.slice(index + 1),
                ],
                names,
              );
            }}
            className="card-text"
            invalid={errors[path] !== undefined}
          />
          <FormFeedback valid={!errors[path]}>
            {errors[path] && errors[path]}
          </FormFeedback>
        </FormGroup>
        <div className="delete-button">
          <FontAwesomeIcon
            icon={faTimes}
            onClick={() => {
              // remove this value
              onChange(
                [
                  ...initialValues.slice(0, index),
                  ...initialValues.slice(index + 1),
                ],
                names
                  ? [...names.slice(0, index), ...names.slice(index + 1)]
                  : undefined,
              );
            }}
          />
        </div>
      </div>,
    );
  }

  return (
    <>
      {possibleValues}
      <FontAwesomeIcon
        icon={faPlus}
        onClick={() => {
          // add a new dropdown option
          onChange(
            [...initialValues, type === 'string' ? '' : 0],
            names ? [...names, ''] : undefined,
          );
        }}
      />
    </>
  );
};
