import * as React from 'react';
import { Input } from 'reactstrap';
import { FBCheckbox } from '../checkbox/FBCheckbox';
import { CardEnumOptions } from '../CardEnumOptions';
import { fetchLabel } from '../utils';
import type {
  Parameters,
  FormInput,
  Mods,
} from '../types';

type Props = {
  parameters: Parameters;
  onChange: (newParams: Parameters) => void;
};
// specify the inputs required for a string type object
export const CardDefaultParameterInputs: React.FC<Props> = () =>
  <div />;

const getInputCardBodyComponent = ({ type }: { type: any }) =>
  ({ parameters, onChange }: {
    parameters: Parameters;
    onChange: (newParams: Parameters) => void;
  }) => (
    <>
      <h5>Default value</h5>
      <Input
        value={parameters.default || ''}
        placeholder="Default"
        type={type}
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
          onChange({ ...parameters, default: ev.target.value })}
        className="card-text"
      />
    </>
  );

const Checkbox = ({
  parameters,
  onChange,
}: {
  parameters: Parameters;
  onChange: (newParams: Parameters) => void;
}) => (
  <div className="card-boolean">
    <FBCheckbox
      onChangeValue={() => {
        onChange({
          ...parameters,
          default: parameters.default
            ? parameters.default !== true
            : true,
        });
      }}
      isChecked={
                    parameters.default ? parameters.default === true : false
                }
      label="Default"
    />
  </div>
);

export const MultipleChoice = ({
  parameters,
  onChange,
  mods,
}: {
  parameters: Parameters;
  onChange: (newParams: Parameters) => void;
  mods: Mods;
}) => {
  const enumArray = Array.isArray(parameters.enum) ? parameters.enum : [];
  // eslint-disable-next-line no-restricted-globals

  const possibleValuesLabel = fetchLabel('possibleValuesLabel', 'Possible Values', mods);

  return (
    <div className="card-enum">
      <h3>{possibleValuesLabel}</h3>
      <CardEnumOptions
        initialValues={enumArray}
        names={undefined}
        onChange={(
          newEnum: Array<string>,
          newEnumNames?: Array<string>,
        ) =>
          onChange({
            ...parameters,
            enum: newEnum,
            enumNames: newEnumNames,
          })}
        type="string"
        mods={mods}
      />
    </div>
  );
};

export const defaultInputs: Record<string, FormInput> = {
  dateTime: {
    displayName: 'Date-Time',
    matchIf: [
      {
        types: ['string'],
        format: 'date-time',
      },
    ],
    defaultDataSchema: {
      format: 'date-time',
    },
    defaultUiSchema: {},
    type: 'string',
    cardBody: getInputCardBodyComponent({
      type: 'datetime-local',
    }),
    modalBody: CardDefaultParameterInputs,
  },
  date: {
    displayName: 'Date',
    matchIf: [
      {
        types: ['string'],
        format: 'date',
      },
    ],
    defaultDataSchema: {
      format: 'date',
    },
    defaultUiSchema: {},
    type: 'string',
    cardBody: getInputCardBodyComponent({
      type: 'date',
    }),
    modalBody: CardDefaultParameterInputs,
  },
  time: {
    displayName: 'Time',
    matchIf: [
      {
        types: ['string'],
        format: 'time',
      },
    ],
    defaultDataSchema: {
      format: 'time',
    },
    defaultUiSchema: {},
    type: 'string',
    cardBody: getInputCardBodyComponent({
      type: 'time',
    }),
    modalBody: CardDefaultParameterInputs,
  },
  checkbox: {
    displayName: 'Checkbox',
    matchIf: [
      {
        types: ['boolean'],
      },
    ],
    defaultDataSchema: {},
    defaultUiSchema: {},
    type: 'boolean',
    cardBody: Checkbox,
    modalBody: CardDefaultParameterInputs,
  },
  radio: {
    displayName: 'Radio',
    matchIf: [
      {
        types: [
          'string',
          'number',
          'integer',
          'array',
          'boolean',
          'null',
        ],
        widget: 'radio',
        enum: true,
      },
    ],
    defaultDataSchema: {
      enum: [],
    },
    defaultUiSchema: {
      'ui:widget': 'radio',
    },
    type: 'string',
    cardBody: MultipleChoice,
    modalBody: CardDefaultParameterInputs,
  },
  dropdown: {
    displayName: 'Dropdown',
    matchIf: [
      {
        types: [
          'string',
          'number',
          'integer',
          'array',
          'boolean',
          'null',
        ],
        enum: true,
      },
    ],
    defaultDataSchema: {
      enum: [],
    },
    defaultUiSchema: {},
    type: 'string',
    cardBody: MultipleChoice,
    modalBody: undefined,
  },
};
