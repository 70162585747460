import React from 'react';
import { createUseStyles } from 'react-jss';
import { Collapse as RSCollapse } from 'reactstrap';
import classnames from 'classnames';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '../FontAwesomeIcon';

const useStyles = createUseStyles({
  collapseElement: {
    '& .disabled': {
      '.toggle-collapse': {
        cursor: 'default',
      },
    },
    '& h4': {
      marginTop: '7px',
      padding: '13px 10px 10px 10px',
    },
    '& .toggle-collapse': {
      fontSize: '2.3rem',
      cursor: 'pointer',
      marginLeft: '33px',
      '& .fa-caret-right': {
        marginRight: '9px',
      },
    },
  },
});

type Props = {
  // Determines if the Collapse component is open
  isOpen: boolean;
  // Toggles the isOpen boolean between true and false
  toggleCollapse: () => void;
  // The title to display in the collapse header
  title: React.ReactNode;
  // Anything to be rendered within the collapse
  children: any;
  // If true will gray out and disable */
  disableToggle?: boolean;
  className?: string;
};

export const Collapse: React.FC<Props> = ({
  isOpen,
  toggleCollapse,
  title,
  children,
  disableToggle,
  className,
}) => {
  const classes = classnames(
    `collapse-element ${className || ''} ${useStyles().collapseElement}`,
    {
      disabled: disableToggle,
    },
  );
  return (
    <div className={classes}>
      <div className="d-flex">
        <span className="toggle-collapse">
          <FontAwesomeIcon
            onClick={!disableToggle ? toggleCollapse : () => {}}
            icon={isOpen ? faCaretDown : faCaretRight}
          />
        </span>
        <h4>{title}</h4>
      </div>
      <RSCollapse isOpen={isOpen}>
        <div>{children}</div>
      </RSCollapse>
    </div>
  );
};
