import * as React from 'react';
import { getCardBody } from './utils';
import type { Parameters, Mods, FormInput } from './types'; // specify the inputs required for any type of object

type GeneralParameterInputsProps = {
  category: string;
  parameters: Parameters;
  onChange: (newParams: Parameters) => void;
  mods?: Mods;
  allFormInputs: Record<string, FormInput>;
};

export const GeneralParameterInputs: React.FC<GeneralParameterInputsProps> = ({
  category,
  parameters,
  onChange,
  mods,
  allFormInputs,
}) => {
  const CardBody = getCardBody(category, allFormInputs);

  return (
    <div>
      <CardBody parameters={parameters} onChange={onChange} mods={mods || {}} />
    </div>
  );
};
