import React, { useState } from 'react';
import classnames from 'classnames';

type FBRadioButtonProps = {
  label: React.ReactNode;
  value?: any;
  name?: string;
  checked?: boolean;
  required?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  onChange: (arg0: any) => void;
  className?: string;
};

/* eslint-disable jsx-a11y/no-autofocus */
export const FBRadioButton: React.FC<FBRadioButtonProps> = ({
  label,
  value,
  checked,
  name,
  onChange,
  required,
  disabled,
  autoFocus,
  className,
}) => {
  const [id] = useState(`radio-${Math.floor(Math.random() * 1000000)}`);
  const classes = classnames(className, {
    disabled,
  });

  return (
    <div className={classes} key={value}>
      <input
        id={id}
        type="radio"
        name={name}
        value={value}
        checked={checked}
        required={required}
        disabled={disabled}
        autoFocus={autoFocus}
        onChange={() => onChange(value)}
      />
      <label
        className="form-check-label"
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  );
};
