import React from 'react';
import classnames from 'classnames';
import { FBRadioButton } from './FBRadioButton';

type FBRadioGroupProps = {
  options: Array<{
    label: React.ReactNode;
    value: string | number;
  }>;
  defaultValue?: any;
  horizontal?: boolean;
  id?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  onChange: (arg0: any) => void;
};

export const FBRadioGroup: React.FC<FBRadioGroupProps> = ({
  options,
  defaultValue,
  onChange,
  horizontal,
  id,
  autoFocus,
  disabled,
}) => {
  const name = Math.random().toString();
  const classes = classnames('fb-radio-group', {
    horizontal,
  });

  return (
    <div id={id} className={`${classes}`}>
      {options.map((option, index) => (
        <FBRadioButton
          value={option.value}
          label={option.label}
          name={name}
          key={option.value}
          checked={option.value === defaultValue}
          autoFocus={autoFocus && index === 1}
          onChange={onChange}
          disabled={disabled}
          className="form-check form-check-inline"
        />
      ))}
    </div>
  );
};
